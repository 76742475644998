import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { bindActionCreators } from "redux";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Formik } from "formik";
import { object, string } from "yup";
import { ConnectedFocusError } from "focus-formik-error";
import { connect } from "react-redux";
import { updateFireAction, signin, resetPassword } from "../actions";

import Spinner from "react-bootstrap/Spinner";

import { MSG_SIGNIN_ERROR } from "../constants";

import { Link, useHistory } from "react-router-dom";
import logo from "../assets/logo.webp";

import Toast from "react-bootstrap/Toast";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputModal from "../components/InputModal";
import { InputGroup } from "react-bootstrap";

const Signin = ({
  signin,
  subPath,
  user,
  storeMsg,
  authMsg,
  resetPassword,
}) => {
  console.log("subPath get data ", subPath);

  const [data, setData] = useState({
    email: "",
    password: "",
    check_textInputChange: false,
    secureTextEntry: true,
    isValidUser: true,
    isValidPassword: true,
    saving: false,
    country_code: "ZM+260",
    header: "",
    body: "",
    show: false,
  });
  const history = useHistory();
  const location = new URL(window.location.href);

  const from = `${location.searchParams.get("from")}`.replace(/[%2F]+/g, "/");
  console.log("from ", from);
  const [payload, setPayload] = useState();

  const [isDisconnected, setDisconnected] = useState(false);

  const [isLink, setLink] = useState(false);
  const [isErr, setErr] = useState(false);
  const [show, setShow] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const resetCallback = (email, err) => {
    setDisabled(false);

    if (err) {
      console.log("signinCallback in err inner " + err);
      setPayload({
        data: { title: "Error", body: err },
        req: ["title"],
        uploading: false,
        action: "Okay",
      });
      setErr(true);
    } else {
      history.replace("/email_verification", {
        type: "Password Reset",
        from,
        email,
      });
    }
  };
  const goTo = () => {
    history.replace(from);
  };
  const signinCallback = (err, type) => {
    console.log("signinCallback in err inner " + err);
    if (err) {
      console.log("signinCallback in err inner " + err);
      setPayload({
        data: { title: "Error", body: err },
        req: ["title"],
        uploading: false,
        action: "Okay",
      });
      setErr(true);
    } else {
      goTo(type);
    }
  };
  function submitToFb(data) {
    setDisabled(true);
    signin(data.email, data.password, signinCallback);
  }

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? "online" : "offline";
    if (condition === "online") {
      const webPing = setInterval(() => {
        fetch("//google.com", {
          mode: "no-cors",
        })
          .then(() => {
            setDisconnected(false);
            return clearInterval(webPing);
          })
          .catch(() => setDisconnected(true));
      }, 2000);
      return;
    }
    return setDisconnected(true);
  };
  useEffect(() => {
    handleConnectionChange();
    window.addEventListener("online", handleConnectionChange);
    window.addEventListener("offline", handleConnectionChange);
  }, []);

  useEffect(() => {
    console.log("authMsg ", authMsg);
    if (
      authMsg ===
      "Your account was successfully created! Please verify your e-mail address in the e-mail we just sent to you."
    ) {
      setData({
        ...data,
        header: "Check Your Email",
        body: authMsg,
        show: true,
      });
    }

    if (authMsg === MSG_SIGNIN_ERROR) {
      setDisabled(false);
      if (data.show) {
        setData({
          ...data,
          header: "Error",
          body: MSG_SIGNIN_ERROR,
          show: true,
        });
      }
    }
    if (isDisconnected) {
      setPayload({
        data: {
          title: "Network Error",
          body: "Please check your internet connection and try again",
        },
        req: ["title"],
        uploading: false,
        action: "Okay",
      });
    }
    setLink(isDisconnected);
  }, [authMsg, user, storeMsg, isDisconnected]);

  return (
    <div className="Container bgg">
      <Toast
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          color: "black",
        }}
        onClose={() => {
          setData({
            ...data,
            show: false,
          });
          setDisabled(false);
        }}
        show={data.show}>
        <Toast.Header>
          <strong className="mr-auto">{data.header}</strong>
        </Toast.Header>
        <Toast.Body>{data.body}.</Toast.Body>
      </Toast>
      <div className="Login-form">
        <div className="App">
          <img alt="" src={logo} width={140} className="App" />
          <h1>Sign in</h1>
        </div>
        <Formik
          enableReinitialize
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={object({
            email: string()
              .email("Enter valid email")
              .required("email required"),
            password: string().required("password required"),
          })}
          onSubmit={submitToFb}>
          {(formik) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}>
              <ConnectedFocusError />
              <Form.Group as={Row} controlId="formBasicEmail">
                <Col>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <Form.Control
                    type="email"
                    name="email"
                    placeholder="Enter email"
                    {...formik.getFieldProps("email")}
                    isValid={formik.touched.email && !formik.errors.email}
                    isInvalid={formik.touched.email && !!formik.errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Col>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={show ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      {...formik.getFieldProps("password")}
                      isValid={
                        formik.touched.password && !formik.errors.password
                      }
                      isInvalid={
                        formik.touched.password && !!formik.errors.password
                      }
                    />

                    <Button
                      onClick={() => setShow(!show)}
                      variant="outline-secondary">
                      {show ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Button
                variant="warning"
                type="submit"
                size="lg"
                block
                disabled={disabled}>
                <div
                  style={{
                    width: "100%",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    display: disabled ? "flex" : "none",
                  }}>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  wait.....
                </div>
                <div
                  style={{
                    width: "100%",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    display: !disabled ? "flex" : "none",
                  }}>
                  Sign in
                </div>
              </Button>
            </Form>
          )}
        </Formik>

        <br />
        <div className="Container-links">
          <Link>
            <h6
              onClick={(e) => {
                e.preventDefault();

                history.push(`/signup/return?from=${from.replace("/", "%2F")}`);
              }}>
              Register here
            </h6>
          </Link>
          <Link>
            <h6
              onClick={(e) => {
                e.preventDefault();
                setPayload({
                  data: {
                    email: "",
                    title: "Reset Password",
                    body: "A reset password link will be sent to your email. Please enter your email address below",
                  },
                  uploading: false,
                  req: ["email"],
                  action: "Send Link",
                });
                setLink(true);
              }}>
              Forgot Password
            </h6>
          </Link>
        </div>
      </div>
      {payload && (
        <InputModal
          payload={payload}
          show={isLink}
          onActionFun={(verificationId) => {
            if (payload.showInput) {
              resetPassword(verificationId, from, resetCallback);
            }
            setLink(false);
          }}
          setUploading={() => setPayload({ ...payload, uploading: true })}
          onHide={() => setLink(false)}
        />
      )}
      {payload && (
        <InputModal
          payload={payload}
          show={isErr}
          onActionFun={() => {
            setErr(false);
          }}
          setUploading={() => setPayload({ ...payload, uploading: true })}
          onHide={() => setErr(false)}
        />
      )}
    </div>
  );
};
function mapStateToProps(state) {
  return {
    authMsg: state.yng_authReducer.authMsg,
    disable: state.yng_authReducer.disable,
    storeMsg: state.yng_datastore.storeMsg,
    uid: state.yng_authReducer.uid,
    user: state.yng_datastore.user,
  };
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateFireAction,
      signin,

      resetPassword,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Signin);
