import profile from "../../assets/profile.webp";
import { Image } from "react-bootstrap";

import { connect } from "react-redux";

function NavIcons({ photoUrl }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "50%",
        padding: 1,
        marginLeft: 10,
      }}>
      <Image
        alt="photoUrl"
        src={photoUrl || profile}
        height={40}
        width={40}
        roundedCircle
      />
    </div>
  );
}
function mapStateToProps({ yng_datastore }) {
  return {
    photoUrl: yng_datastore?.user?.photoUrl,
  };
}

export default connect(mapStateToProps)(NavIcons);
