import React, { useEffect, useRef, useState } from "react";
import { Button, ProgressBar, Spinner } from "react-bootstrap";
import { useHistory } from "react-router";
import logo from "../assets/logo.webp";
import { connect } from "react-redux";
import InputModal from "../components/InputModal";
import { bindActionCreators } from "redux";
import { currentAction } from "../actions";
import { DOMAIN } from "../constants";
import firebaseApp from "../config/fbConfig";

import {
  getAuth,
  signInWithEmailAndPassword,
  sendEmailVerification,
  confirmPasswordReset,
  verifyPasswordResetCode,
  applyActionCode,
  checkActionCode,
} from "firebase/auth";

const auth = getAuth(firebaseApp);

function Auth({ current, currentAction }) {
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const ref = useRef();
  const [title, setTitle] = useState("Verifying Your Account");
  const [sub, setSub] = useState("Please wait...");
  const [isProg, setProg] = useState(true);
  const [link, setLink] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const [payload, setPayload] = useState({
    data: {
      password: "",
      title: "Reset Password",
      body: "Enter new password to reset your account",
    },
    uploading: false,
    req: ["password"],
    action: "Reset",
  });
  const emailCallback = (email, err) => {
    setSearching(false);
    if (err) {
      setTitle("Error");
      setSub(err);
      setLink(
        !current.isEmpty && ref.current.mode === "verifyEmail"
          ? "Send link"
          : null
      );
    } else {
      const type =
        ref.current.mode === "resetPassword"
          ? "Password Reset"
          : ref.current.mode === "verifyEmail"
          ? "Email Verification"
          : "Confirm Email";
      console.log("type ", type, "email ", email);
      history.replace("/email_verification", {
        from: ref.current.from,
        type,
        email,
      });
    }
  };
  const authCallback = async (err, msg) => {
    setProg(false);
    if (err) {
      setTitle("Error");
      setSub(err);
      setLink(
        !current.isEmpty && ref.current.mode === "verifyEmail"
          ? "Send link"
          : null
      );
    } else {
      setSub(msg);
      if (!current.isEmpty) {
        currentAction({ ...current, emailVerified: true });
      }

      history.replace(
        ref.current.from.replace("%20", " ").replace(/[AA]+/g, "&"),
        { reAuth: true }
      );
    }
  };
  const signin = (email, password) => async () => {
    try {
      console.log("sign in");
      return signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          console.log("sign in success");

          authCallback(
            null,
            "Password reset has been confirmed and new password updated"
          );
        })
        .catch((err) => {
          console.log("sign in err in " + err);
          try {
            authCallback(err.message);
          } catch (err) {
            authCallback("Please try again.");
          }
        });
    } catch (err) {
      console.log("sign in err inner " + err);
      try {
        authCallback(err.message);
      } catch (err) {
        authCallback("Please try again.");
      }
    }
  };
  const sendVerifyEmail = () => {
    try {
      const actionCodeSettings = {
        url: DOMAIN + ref.current.urlHref,
      };
      const user = auth.currentUser;

      return sendEmailVerification(user, actionCodeSettings).then(
        () => {
          console.log("sign inner auth  successfully ", user.email);

          emailCallback(user.email);
        },
        function (err) {
          // An error happened.
          console.log("sign inner auth  err ", err.message);

          emailCallback(null, err.message);
        }
      );
    } catch (err) {
      emailCallback(null, "Please try again.");
    }
  };
  const handleReset = (newPassword) => {
    // Save the new password.
    return confirmPasswordReset(auth, ref.current.actionCode, newPassword)
      .then((resp) => {
        // Password reset has been confirmed and new password updated.
        // TODO: Display a link back to the app, or sign-in the user directly
        // if the page belongs to the same domain as the app:
        // auth.signInWithEmailAndPassword(accountEmail, newPassword);
        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        console.log("newPassword handleReset ", newPassword);
        signin(payload.email, newPassword);
      })
      .catch((error) => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        console.log("newPassword handleReset err ", newPassword);

        authCallback(error.message, null, null);
      });
  };
  async function handleResetPassword(actionCode) {
    // Localize the UI to the selecteuage as determined by th
    // parameter.
    setTitle("Reset password");
    // Verify the password reset code is valid.
    try {
      const email = await verifyPasswordResetCode(auth, actionCode);
      setPayload({ ...payload, data: { ...payload.data, email } });
      setModalShow(true);
    } catch (error) {
      // Invalid or expired action code. Ask user to try to reset the password
      // again.
      authCallback(error.message, null, null);
    }
  }
  function handleRecoverEmail(actionCode) {
    // Localize the UI to the selecteuage as determined by th
    // parameter.
    let restoredEmail = null;
    setTitle("Confirm email");

    // Confirm the action code is valid.
    return checkActionCode(auth, actionCode)
      .then((info) => {
        // Get the restored email address.
        // Revert to the old email.
        return applyActionCode(auth, actionCode);
      })
      .then(() => {
        // Account email reverted to restoredEmail

        // TODO: Display a confirmation message to the user.

        // You might also want to give the user the option to reset their password
        // in case the account was compromised:
        authCallback(null, "Account email reverted to restoredEmail");
      })
      .catch((error) => {
        // Invalid code.\
        authCallback(error.message, null, null);
      });
  }
  function handleVerifyEmail(actionCode) {
    // Localize the UI to the selecteuage as determined by th
    // parameter.
    // Try to apply the email verification code.

    return applyActionCode(auth, actionCode)
      .then(() => {
        // Email address has been verified.

        // TODO: Display a confirmation message to the user.
        // You could also provide the user with a link back to the app.

        // TODO: If a continue URL is available, display a button which on
        // click redirects the user back to the app via continueUrl with
        // additional state determined from that URL's parameters.
        authCallback(null, "Email address has been verified");
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        authCallback(error.message, null, null);
      });
  }
  const expAuth = (actionCode, mode) => {
    // Handle the user management action.
    switch (mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        handleResetPassword(actionCode);
        break;
      case "recoverEmail":
        // Display email recovery handler and UI.
        handleRecoverEmail(actionCode);
        break;
      case "verifyEmail":
        // Display email verification handler and UI.
        handleVerifyEmail(actionCode);
        break;
      default:
      // Error: invalid mode.
    }
  };
  useEffect(() => {
    const location = new URL(window.location.href);

    const mode = location.searchParams.get("mode");
    // Get the one-time code from the query parameter.
    const actionCode = location.searchParams.get("oobCode");
    // (Optional) Get the continue URL from the query parameter if available.
    const continueUrl = location.searchParams.get("continueUrl");
    // (Optional) Get the language code if available.
    const url = new URL(continueUrl);
    ref.current = {
      from: url.pathname ? url.pathname : "/",
      actionCode,
      mode,
    };
    console.log(
      "location ",
      location,
      "mode ",
      mode,
      "ref.current ",
      ref.current
    );
    expAuth(actionCode, mode);
  }, []);
  return (
    <div className="Container bgg">
      <div className="Login-form">
        {isProg && <ProgressBar animated now={100} />}

        <div className="App">
          <img alt="" src={logo} width={140} className="App" />
          <h1>{title}</h1>
          {sub && <p>{sub}</p>}
        </div>
        {link && (
          <Button
            variant="warning"
            type="button"
            size="lg"
            block
            disabled={searching}
            onClick={() => {
              setSearching(true);

              sendVerifyEmail();
            }}>
            <div
              style={{
                width: "100%",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                display: searching ? "flex" : "none",
              }}>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              wait.....
            </div>
            <div
              style={{
                width: "100%",
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
                display: !searching ? "flex" : "none",
              }}>
              {link}
            </div>
          </Button>
        )}
      </div>
      <InputModal
        payload={payload}
        show={modalShow}
        onActionFun={({ password }) => {
          console.log("newPassword ", password);
          handleReset(password);
          setModalShow(false);
        }}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
}
const mapStateToProps = ({ yng_authReducer }) => {
  return {
    current: yng_authReducer.current,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      currentAction,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Auth);
