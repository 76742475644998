import part1 from "../assets/actionaid.jpg";
import part2 from "../assets/wwf.webp";
import part3 from "../assets/mashmap.jfif";
import part4 from "../assets/agents.webp";
import part5 from "../assets/gaia.png";
import part6 from "../assets/tuzini.jfif";
import part7 from "../assets/oxfam.webp";
import part8 from "../assets/solidaridad.webp";
import part9 from "../assets/csef.webp";
import part10 from "../assets/agripro.webp";

import nawa from "../assets/nawa.webp";
import richy from "../assets/richy.webp";
import veri from "../assets/veri.webp";
import mash from "../assets/mash.webp";
import Sandie from "../assets/sandie.webp";
import ang from "../assets/ang.webp";
import priscilla from "../assets/priscilla.webp";
import valepi from "../assets/valepi.jpg";
import luci from "../assets/lucy.webp";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { AiFillTwitterCircle } from "react-icons/ai";

export const partner1 = [part1, part2, part4, part3, part6, part5];
export const partner2 = [part7, part8, part10, part9];

export const icons = [
  {
    icon: FaFacebook,
    info: "https://www.facebook.com/YOUNGEMERGINGFARMERSINITIATIVE/",
    color: "#4267B2",
  },
  {
    icon: AiFillTwitterCircle,
    info: "https://twitter.com/YEFI_OFFICIAL?s=08",
    color: "#00acee",
  },
  {
    icon: FaInstagram,
    info: "https://instagram.com/yefi_official?igshid=e4gs23i8faa8",
    color: "#8a3ab9",
  },
];
export const team = [
  { title: "Richard Kachungu", position: "Executive Director", url: richy },
  {
    title: "Nawa Joe Silishebo",
    position: "Admin & Projects Director",
    url: nawa,
  },
  { title: "Veronica Wangwe", position: "Communication Manager", url: veri },
  {
    title: "Sandie Muchaila",
    position: "Production and Consultancy Manager",
    url: Sandie,
  },
  { title: "Brian Munalula", position: "IT Specialist", url: mash },
  {
    title: "Priscilla Chitimwango",
    position: "Programs/M&E Officer",
    url: priscilla,
  },
  { title: "Eglina Mpanga", position: "Project Officer", url: ang },
  {
    title: "Valepi Banda",
    position: "Project Officer: Agriculture and Environment",
    url: valepi,
  },
  { title: "Lucy Namwila", position: "Admin Officer", url: luci },
];
export const countries = [
  {
    countryCode: "AD",
    countryName: "Andorra",
    currencyCode: "EUR",
    population: "84000",
    capital: "Andorra la Vella",
    continentName: "Europe",
  },
  {
    countryCode: "AE",
    countryName: "United Arab Emirates",
    currencyCode: "AED",
    population: "4975593",
    capital: "Abu Dhabi",
    continentName: "Asia",
  },
  {
    countryCode: "AF",
    countryName: "Afghanistan",
    currencyCode: "AFN",
    population: "29121286",
    capital: "Kabul",
    continentName: "Asia",
  },
  {
    countryCode: "AG",
    countryName: "Antigua and Barbuda",
    currencyCode: "XCD",
    population: "86754",
    capital: "St. John's",
    continentName: "North America",
  },
  {
    countryCode: "AI",
    countryName: "Anguilla",
    currencyCode: "XCD",
    population: "13254",
    capital: "The Valley",
    continentName: "North America",
  },
  {
    countryCode: "AL",
    countryName: "Albania",
    currencyCode: "ALL",
    population: "2986952",
    capital: "Tirana",
    continentName: "Europe",
  },
  {
    countryCode: "AM",
    countryName: "Armenia",
    currencyCode: "AMD",
    population: "2968000",
    capital: "Yerevan",
    continentName: "Asia",
  },
  {
    countryCode: "AO",
    countryName: "Angola",
    currencyCode: "AOA",
    population: "13068161",
    capital: "Luanda",
    continentName: "Africa",
  },
  {
    countryCode: "AQ",
    countryName: "Antarctica",
    currencyCode: "",
    population: "0",
    capital: "",
    continentName: "Antarctica",
  },
  {
    countryCode: "AR",
    countryName: "Argentina",
    currencyCode: "ARS",
    population: "41343201",
    capital: "Buenos Aires",
    continentName: "South America",
  },
  {
    countryCode: "AS",
    countryName: "American Samoa",
    currencyCode: "USD",
    population: "57881",
    capital: "Pago Pago",
    continentName: "Oceania",
  },
  {
    countryCode: "AT",
    countryName: "Austria",
    currencyCode: "EUR",
    population: "8205000",
    capital: "Vienna",
    continentName: "Europe",
  },
  {
    countryCode: "AU",
    countryName: "Australia",
    currencyCode: "AUD",
    population: "21515754",
    capital: "Canberra",
    continentName: "Oceania",
  },
  {
    countryCode: "AW",
    countryName: "Aruba",
    currencyCode: "AWG",
    population: "71566",
    capital: "Oranjestad",
    continentName: "North America",
  },
  {
    countryCode: "AX",
    countryName: "Åland",
    currencyCode: "EUR",
    population: "26711",
    capital: "Mariehamn",
    continentName: "Europe",
  },
  {
    countryCode: "AZ",
    countryName: "Azerbaijan",
    currencyCode: "AZN",
    population: "8303512",
    capital: "Baku",
    continentName: "Asia",
  },
  {
    countryCode: "BA",
    countryName: "Bosnia and Herzegovina",
    currencyCode: "BAM",
    population: "4590000",
    capital: "Sarajevo",
    continentName: "Europe",
  },
  {
    countryCode: "BB",
    countryName: "Barbados",
    currencyCode: "BBD",
    population: "285653",
    capital: "Bridgetown",
    continentName: "North America",
  },
  {
    countryCode: "BD",
    countryName: "Bangladesh",
    currencyCode: "BDT",
    population: "156118464",
    capital: "Dhaka",
    continentName: "Asia",
  },
  {
    countryCode: "BE",
    countryName: "Belgium",
    currencyCode: "EUR",
    population: "10403000",
    capital: "Brussels",
    continentName: "Europe",
  },
  {
    countryCode: "BF",
    countryName: "Burkina Faso",
    currencyCode: "XOF",
    population: "16241811",
    capital: "Ouagadougou",
    continentName: "Africa",
  },
  {
    countryCode: "BG",
    countryName: "Bulgaria",
    currencyCode: "BGN",
    population: "7148785",
    capital: "Sofia",
    continentName: "Europe",
  },
  {
    countryCode: "BH",
    countryName: "Bahrain",
    currencyCode: "BHD",
    population: "738004",
    capital: "Manama",
    continentName: "Asia",
  },
  {
    countryCode: "BI",
    countryName: "Burundi",
    currencyCode: "BIF",
    population: "9863117",
    capital: "Bujumbura",
    continentName: "Africa",
  },
  {
    countryCode: "BJ",
    countryName: "Benin",
    currencyCode: "XOF",
    population: "9056010",
    capital: "Porto-Novo",
    continentName: "Africa",
  },
  {
    countryCode: "BL",
    countryName: "Saint Barthélemy",
    currencyCode: "EUR",
    population: "8450",
    capital: "Gustavia",
    continentName: "North America",
  },
  {
    countryCode: "BM",
    countryName: "Bermuda",
    currencyCode: "BMD",
    population: "65365",
    capital: "Hamilton",
    continentName: "North America",
  },
  {
    countryCode: "BN",
    countryName: "Brunei",
    currencyCode: "BND",
    population: "395027",
    capital: "Bandar Seri Begawan",
    continentName: "Asia",
  },
  {
    countryCode: "BO",
    countryName: "Bolivia",
    currencyCode: "BOB",
    population: "9947418",
    capital: "Sucre",
    continentName: "South America",
  },
  {
    countryCode: "BQ",
    countryName: "Bonaire",
    currencyCode: "USD",
    population: "18012",
    capital: "Kralendijk",
    continentName: "North America",
  },
  {
    countryCode: "BR",
    countryName: "Brazil",
    currencyCode: "BRL",
    population: "201103330",
    capital: "Brasília",
    continentName: "South America",
  },
  {
    countryCode: "BS",
    countryName: "Bahamas",
    currencyCode: "BSD",
    population: "301790",
    capital: "Nassau",
    continentName: "North America",
  },
  {
    countryCode: "BT",
    countryName: "Bhutan",
    currencyCode: "BTN",
    population: "699847",
    capital: "Thimphu",
    continentName: "Asia",
  },
  {
    countryCode: "BV",
    countryName: "Bouvet Island",
    currencyCode: "NOK",
    population: "0",
    capital: "",
    continentName: "Antarctica",
  },
  {
    countryCode: "BW",
    countryName: "Botswana",
    currencyCode: "BWP",
    population: "2029307",
    capital: "Gaborone",
    continentName: "Africa",
  },
  {
    countryCode: "BY",
    countryName: "Belarus",
    currencyCode: "BYR",
    population: "9685000",
    capital: "Minsk",
    continentName: "Europe",
  },
  {
    countryCode: "BZ",
    countryName: "Belize",
    currencyCode: "BZD",
    population: "314522",
    capital: "Belmopan",
    continentName: "North America",
  },
  {
    countryCode: "CA",
    countryName: "Canada",
    currencyCode: "CAD",
    population: "33679000",
    capital: "Ottawa",
    continentName: "North America",
  },
  {
    countryCode: "CC",
    countryName: "Cocos [Keeling] Islands",
    currencyCode: "AUD",
    population: "628",
    capital: "West Island",
    continentName: "Asia",
  },
  {
    countryCode: "CD",
    countryName: "Democratic Republic of the Congo",
    currencyCode: "CDF",
    population: "70916439",
    capital: "Kinshasa",
    continentName: "Africa",
  },
  {
    countryCode: "CF",
    countryName: "Central African Republic",
    currencyCode: "XAF",
    population: "4844927",
    capital: "Bangui",
    continentName: "Africa",
  },
  {
    countryCode: "CG",
    countryName: "Republic of the Congo",
    currencyCode: "XAF",
    population: "3039126",
    capital: "Brazzaville",
    continentName: "Africa",
  },
  {
    countryCode: "CH",
    countryName: "Switzerland",
    currencyCode: "CHF",
    population: "7581000",
    capital: "Bern",
    continentName: "Europe",
  },
  {
    countryCode: "CI",
    countryName: "Ivory Coast",
    currencyCode: "XOF",
    population: "21058798",
    capital: "Yamoussoukro",
    continentName: "Africa",
  },
  {
    countryCode: "CK",
    countryName: "Cook Islands",
    currencyCode: "NZD",
    population: "21388",
    capital: "Avarua",
    continentName: "Oceania",
  },
  {
    countryCode: "CL",
    countryName: "Chile",
    currencyCode: "CLP",
    population: "16746491",
    capital: "Santiago",
    continentName: "South America",
  },
  {
    countryCode: "CM",
    countryName: "Cameroon",
    currencyCode: "XAF",
    population: "19294149",
    capital: "Yaoundé",
    continentName: "Africa",
  },
  {
    countryCode: "CN",
    countryName: "China",
    currencyCode: "CNY",
    population: "1330044000",
    capital: "Beijing",
    continentName: "Asia",
  },
  {
    countryCode: "CO",
    countryName: "Colombia",
    currencyCode: "COP",
    population: "47790000",
    capital: "Bogotá",
    continentName: "South America",
  },
  {
    countryCode: "CR",
    countryName: "Costa Rica",
    currencyCode: "CRC",
    population: "4516220",
    capital: "San José",
    continentName: "North America",
  },
  {
    countryCode: "CU",
    countryName: "Cuba",
    currencyCode: "CUP",
    population: "11423000",
    capital: "Havana",
    continentName: "North America",
  },
  {
    countryCode: "CV",
    countryName: "Cape Verde",
    currencyCode: "CVE",
    population: "508659",
    capital: "Praia",
    continentName: "Africa",
  },
  {
    countryCode: "CW",
    countryName: "Curacao",
    currencyCode: "ANG",
    population: "141766",
    capital: "Willemstad",
    continentName: "North America",
  },
  {
    countryCode: "CX",
    countryName: "Christmas Island",
    currencyCode: "AUD",
    population: "1500",
    capital: "Flying Fish Cove",
    continentName: "Asia",
  },
  {
    countryCode: "CY",
    countryName: "Cyprus",
    currencyCode: "EUR",
    population: "1102677",
    capital: "Nicosia",
    continentName: "Europe",
  },
  {
    countryCode: "CZ",
    countryName: "Czechia",
    currencyCode: "CZK",
    population: "10476000",
    capital: "Prague",
    continentName: "Europe",
  },
  {
    countryCode: "DE",
    countryName: "Germany",
    currencyCode: "EUR",
    population: "81802257",
    capital: "Berlin",
    continentName: "Europe",
  },
  {
    countryCode: "DJ",
    countryName: "Djibouti",
    currencyCode: "DJF",
    population: "740528",
    capital: "Djibouti",
    continentName: "Africa",
  },
  {
    countryCode: "DK",
    countryName: "Denmark",
    currencyCode: "DKK",
    population: "5484000",
    capital: "Copenhagen",
    continentName: "Europe",
  },
  {
    countryCode: "DM",
    countryName: "Dominica",
    currencyCode: "XCD",
    population: "72813",
    capital: "Roseau",
    continentName: "North America",
  },
  {
    countryCode: "DO",
    countryName: "Dominican Republic",
    currencyCode: "DOP",
    population: "9823821",
    capital: "Santo Domingo",
    continentName: "North America",
  },
  {
    countryCode: "DZ",
    countryName: "Algeria",
    currencyCode: "DZD",
    population: "34586184",
    capital: "Algiers",
    continentName: "Africa",
  },
  {
    countryCode: "EC",
    countryName: "Ecuador",
    currencyCode: "USD",
    population: "14790608",
    capital: "Quito",
    continentName: "South America",
  },
  {
    countryCode: "EE",
    countryName: "Estonia",
    currencyCode: "EUR",
    population: "1291170",
    capital: "Tallinn",
    continentName: "Europe",
  },
  {
    countryCode: "EG",
    countryName: "Egypt",
    currencyCode: "EGP",
    population: "80471869",
    capital: "Cairo",
    continentName: "Africa",
  },
  {
    countryCode: "EH",
    countryName: "Western Sahara",
    currencyCode: "MAD",
    population: "273008",
    capital: "Laâyoune / El Aaiún",
    continentName: "Africa",
  },
  {
    countryCode: "ER",
    countryName: "Eritrea",
    currencyCode: "ERN",
    population: "5792984",
    capital: "Asmara",
    continentName: "Africa",
  },
  {
    countryCode: "ES",
    countryName: "Spain",
    currencyCode: "EUR",
    population: "46505963",
    capital: "Madrid",
    continentName: "Europe",
  },
  {
    countryCode: "ET",
    countryName: "Ethiopia",
    currencyCode: "ETB",
    population: "88013491",
    capital: "Addis Ababa",
    continentName: "Africa",
  },
  {
    countryCode: "FI",
    countryName: "Finland",
    currencyCode: "EUR",
    population: "5244000",
    capital: "Helsinki",
    continentName: "Europe",
  },
  {
    countryCode: "FJ",
    countryName: "Fiji",
    currencyCode: "FJD",
    population: "875983",
    capital: "Suva",
    continentName: "Oceania",
  },
  {
    countryCode: "FK",
    countryName: "Falkland Islands",
    currencyCode: "FKP",
    population: "2638",
    capital: "Stanley",
    continentName: "South America",
  },
  {
    countryCode: "FM",
    countryName: "Micronesia",
    currencyCode: "USD",
    population: "107708",
    capital: "Palikir",
    continentName: "Oceania",
  },
  {
    countryCode: "FO",
    countryName: "Faroe Islands",
    currencyCode: "DKK",
    population: "48228",
    capital: "Tórshavn",
    continentName: "Europe",
  },
  {
    countryCode: "FR",
    countryName: "France",
    currencyCode: "EUR",
    population: "64768389",
    capital: "Paris",
    continentName: "Europe",
  },
  {
    countryCode: "GA",
    countryName: "Gabon",
    currencyCode: "XAF",
    population: "1545255",
    capital: "Libreville",
    continentName: "Africa",
  },
  {
    countryCode: "GB",
    countryName: "United Kingdom",
    currencyCode: "GBP",
    population: "62348447",
    capital: "London",
    continentName: "Europe",
  },
  {
    countryCode: "GD",
    countryName: "Grenada",
    currencyCode: "XCD",
    population: "107818",
    capital: "St. George's",
    continentName: "North America",
  },
  {
    countryCode: "GE",
    countryName: "Georgia",
    currencyCode: "GEL",
    population: "4630000",
    capital: "Tbilisi",
    continentName: "Asia",
  },
  {
    countryCode: "GF",
    countryName: "French Guiana",
    currencyCode: "EUR",
    population: "195506",
    capital: "Cayenne",
    continentName: "South America",
  },
  {
    countryCode: "GG",
    countryName: "Guernsey",
    currencyCode: "GBP",
    population: "65228",
    capital: "St Peter Port",
    continentName: "Europe",
  },
  {
    countryCode: "GH",
    countryName: "Ghana",
    currencyCode: "GHS",
    population: "24339838",
    capital: "Accra",
    continentName: "Africa",
  },
  {
    countryCode: "GI",
    countryName: "Gibraltar",
    currencyCode: "GIP",
    population: "27884",
    capital: "Gibraltar",
    continentName: "Europe",
  },
  {
    countryCode: "GL",
    countryName: "Greenland",
    currencyCode: "DKK",
    population: "56375",
    capital: "Nuuk",
    continentName: "North America",
  },
  {
    countryCode: "GM",
    countryName: "Gambia",
    currencyCode: "GMD",
    population: "1593256",
    capital: "Bathurst",
    continentName: "Africa",
  },
  {
    countryCode: "GN",
    countryName: "Guinea",
    currencyCode: "GNF",
    population: "10324025",
    capital: "Conakry",
    continentName: "Africa",
  },
  {
    countryCode: "GP",
    countryName: "Guadeloupe",
    currencyCode: "EUR",
    population: "443000",
    capital: "Basse-Terre",
    continentName: "North America",
  },
  {
    countryCode: "GQ",
    countryName: "Equatorial Guinea",
    currencyCode: "XAF",
    population: "1014999",
    capital: "Malabo",
    continentName: "Africa",
  },
  {
    countryCode: "GR",
    countryName: "Greece",
    currencyCode: "EUR",
    population: "11000000",
    capital: "Athens",
    continentName: "Europe",
  },
  {
    countryCode: "GS",
    countryName: "South Georgia and the South Sandwich Islands",
    currencyCode: "GBP",
    population: "30",
    capital: "Grytviken",
    continentName: "Antarctica",
  },
  {
    countryCode: "GT",
    countryName: "Guatemala",
    currencyCode: "GTQ",
    population: "13550440",
    capital: "Guatemala City",
    continentName: "North America",
  },
  {
    countryCode: "GU",
    countryName: "Guam",
    currencyCode: "USD",
    population: "159358",
    capital: "Hagåtña",
    continentName: "Oceania",
  },
  {
    countryCode: "GW",
    countryName: "Guinea-Bissau",
    currencyCode: "XOF",
    population: "1565126",
    capital: "Bissau",
    continentName: "Africa",
  },
  {
    countryCode: "GY",
    countryName: "Guyana",
    currencyCode: "GYD",
    population: "748486",
    capital: "Georgetown",
    continentName: "South America",
  },
  {
    countryCode: "HK",
    countryName: "Hong Kong",
    currencyCode: "HKD",
    population: "6898686",
    capital: "Hong Kong",
    continentName: "Asia",
  },
  {
    countryCode: "HM",
    countryName: "Heard Island and McDonald Islands",
    currencyCode: "AUD",
    population: "0",
    capital: "",
    continentName: "Antarctica",
  },
  {
    countryCode: "HN",
    countryName: "Honduras",
    currencyCode: "HNL",
    population: "7989415",
    capital: "Tegucigalpa",
    continentName: "North America",
  },
  {
    countryCode: "HR",
    countryName: "Croatia",
    currencyCode: "HRK",
    population: "4284889",
    capital: "Zagreb",
    continentName: "Europe",
  },
  {
    countryCode: "HT",
    countryName: "Haiti",
    currencyCode: "HTG",
    population: "9648924",
    capital: "Port-au-Prince",
    continentName: "North America",
  },
  {
    countryCode: "HU",
    countryName: "Hungary",
    currencyCode: "HUF",
    population: "9982000",
    capital: "Budapest",
    continentName: "Europe",
  },
  {
    countryCode: "ID",
    countryName: "Indonesia",
    currencyCode: "IDR",
    population: "242968342",
    capital: "Jakarta",
    continentName: "Asia",
  },
  {
    countryCode: "IE",
    countryName: "Ireland",
    currencyCode: "EUR",
    population: "4622917",
    capital: "Dublin",
    continentName: "Europe",
  },
  {
    countryCode: "IL",
    countryName: "Israel",
    currencyCode: "ILS",
    population: "7353985",
    capital: "",
    continentName: "Asia",
  },
  {
    countryCode: "IM",
    countryName: "Isle of Man",
    currencyCode: "GBP",
    population: "75049",
    capital: "Douglas",
    continentName: "Europe",
  },
  {
    countryCode: "IN",
    countryName: "India",
    currencyCode: "INR",
    population: "1173108018",
    capital: "New Delhi",
    continentName: "Asia",
  },
  {
    countryCode: "IO",
    countryName: "British Indian Ocean Territory",
    currencyCode: "USD",
    population: "4000",
    capital: "",
    continentName: "Asia",
  },
  {
    countryCode: "IQ",
    countryName: "Iraq",
    currencyCode: "IQD",
    population: "29671605",
    capital: "Baghdad",
    continentName: "Asia",
  },
  {
    countryCode: "IR",
    countryName: "Iran",
    currencyCode: "IRR",
    population: "76923300",
    capital: "Tehran",
    continentName: "Asia",
  },
  {
    countryCode: "IS",
    countryName: "Iceland",
    currencyCode: "ISK",
    population: "308910",
    capital: "Reykjavik",
    continentName: "Europe",
  },
  {
    countryCode: "IT",
    countryName: "Italy",
    currencyCode: "EUR",
    population: "60340328",
    capital: "Rome",
    continentName: "Europe",
  },
  {
    countryCode: "JE",
    countryName: "Jersey",
    currencyCode: "GBP",
    population: "90812",
    capital: "Saint Helier",
    continentName: "Europe",
  },
  {
    countryCode: "JM",
    countryName: "Jamaica",
    currencyCode: "JMD",
    population: "2847232",
    capital: "Kingston",
    continentName: "North America",
  },
  {
    countryCode: "JO",
    countryName: "Jordan",
    currencyCode: "JOD",
    population: "6407085",
    capital: "Amman",
    continentName: "Asia",
  },
  {
    countryCode: "JP",
    countryName: "Japan",
    currencyCode: "JPY",
    population: "127288000",
    capital: "Tokyo",
    continentName: "Asia",
  },
  {
    countryCode: "KE",
    countryName: "Kenya",
    currencyCode: "KES",
    population: "40046566",
    capital: "Nairobi",
    continentName: "Africa",
  },
  {
    countryCode: "KG",
    countryName: "Kyrgyzstan",
    currencyCode: "KGS",
    population: "5776500",
    capital: "Bishkek",
    continentName: "Asia",
  },
  {
    countryCode: "KH",
    countryName: "Cambodia",
    currencyCode: "KHR",
    population: "14453680",
    capital: "Phnom Penh",
    continentName: "Asia",
  },
  {
    countryCode: "KI",
    countryName: "Kiribati",
    currencyCode: "AUD",
    population: "92533",
    capital: "Tarawa",
    continentName: "Oceania",
  },
  {
    countryCode: "KM",
    countryName: "Comoros",
    currencyCode: "KMF",
    population: "773407",
    capital: "Moroni",
    continentName: "Africa",
  },
  {
    countryCode: "KN",
    countryName: "Saint Kitts and Nevis",
    currencyCode: "XCD",
    population: "51134",
    capital: "Basseterre",
    continentName: "North America",
  },
  {
    countryCode: "KP",
    countryName: "North Korea",
    currencyCode: "KPW",
    population: "22912177",
    capital: "Pyongyang",
    continentName: "Asia",
  },
  {
    countryCode: "KR",
    countryName: "South Korea",
    currencyCode: "KRW",
    population: "48422644",
    capital: "Seoul",
    continentName: "Asia",
  },
  {
    countryCode: "KW",
    countryName: "Kuwait",
    currencyCode: "KWD",
    population: "2789132",
    capital: "Kuwait City",
    continentName: "Asia",
  },
  {
    countryCode: "KY",
    countryName: "Cayman Islands",
    currencyCode: "KYD",
    population: "44270",
    capital: "George Town",
    continentName: "North America",
  },
  {
    countryCode: "KZ",
    countryName: "Kazakhstan",
    currencyCode: "KZT",
    population: "15340000",
    capital: "Astana",
    continentName: "Asia",
  },
  {
    countryCode: "LA",
    countryName: "Laos",
    currencyCode: "LAK",
    population: "6368162",
    capital: "Vientiane",
    continentName: "Asia",
  },
  {
    countryCode: "LB",
    countryName: "Lebanon",
    currencyCode: "LBP",
    population: "4125247",
    capital: "Beirut",
    continentName: "Asia",
  },
  {
    countryCode: "LC",
    countryName: "Saint Lucia",
    currencyCode: "XCD",
    population: "160922",
    capital: "Castries",
    continentName: "North America",
  },
  {
    countryCode: "LI",
    countryName: "Liechtenstein",
    currencyCode: "CHF",
    population: "35000",
    capital: "Vaduz",
    continentName: "Europe",
  },
  {
    countryCode: "LK",
    countryName: "Sri Lanka",
    currencyCode: "LKR",
    population: "21513990",
    capital: "Colombo",
    continentName: "Asia",
  },
  {
    countryCode: "LR",
    countryName: "Liberia",
    currencyCode: "LRD",
    population: "3685076",
    capital: "Monrovia",
    continentName: "Africa",
  },
  {
    countryCode: "LS",
    countryName: "Lesotho",
    currencyCode: "LSL",
    population: "1919552",
    capital: "Maseru",
    continentName: "Africa",
  },
  {
    countryCode: "LT",
    countryName: "Lithuania",
    currencyCode: "EUR",
    population: "2944459",
    capital: "Vilnius",
    continentName: "Europe",
  },
  {
    countryCode: "LU",
    countryName: "Luxembourg",
    currencyCode: "EUR",
    population: "497538",
    capital: "Luxembourg",
    continentName: "Europe",
  },
  {
    countryCode: "LV",
    countryName: "Latvia",
    currencyCode: "EUR",
    population: "2217969",
    capital: "Riga",
    continentName: "Europe",
  },
  {
    countryCode: "LY",
    countryName: "Libya",
    currencyCode: "LYD",
    population: "6461454",
    capital: "Tripoli",
    continentName: "Africa",
  },
  {
    countryCode: "MA",
    countryName: "Morocco",
    currencyCode: "MAD",
    population: "33848242",
    capital: "Rabat",
    continentName: "Africa",
  },
  {
    countryCode: "MC",
    countryName: "Monaco",
    currencyCode: "EUR",
    population: "32965",
    capital: "Monaco",
    continentName: "Europe",
  },
  {
    countryCode: "MD",
    countryName: "Moldova",
    currencyCode: "MDL",
    population: "4324000",
    capital: "Chişinău",
    continentName: "Europe",
  },
  {
    countryCode: "ME",
    countryName: "Montenegro",
    currencyCode: "EUR",
    population: "666730",
    capital: "Podgorica",
    continentName: "Europe",
  },
  {
    countryCode: "MF",
    countryName: "Saint Martin",
    currencyCode: "EUR",
    population: "35925",
    capital: "Marigot",
    continentName: "North America",
  },
  {
    countryCode: "MG",
    countryName: "Madagascar",
    currencyCode: "MGA",
    population: "21281844",
    capital: "Antananarivo",
    continentName: "Africa",
  },
  {
    countryCode: "MH",
    countryName: "Marshall Islands",
    currencyCode: "USD",
    population: "65859",
    capital: "Majuro",
    continentName: "Oceania",
  },
  {
    countryCode: "MK",
    countryName: "Macedonia",
    currencyCode: "MKD",
    population: "2062294",
    capital: "Skopje",
    continentName: "Europe",
  },
  {
    countryCode: "ML",
    countryName: "Mali",
    currencyCode: "XOF",
    population: "13796354",
    capital: "Bamako",
    continentName: "Africa",
  },
  {
    countryCode: "MM",
    countryName: "Myanmar [Burma]",
    currencyCode: "MMK",
    population: "53414374",
    capital: "Naypyitaw",
    continentName: "Asia",
  },
  {
    countryCode: "MN",
    countryName: "Mongolia",
    currencyCode: "MNT",
    population: "3086918",
    capital: "Ulan Bator",
    continentName: "Asia",
  },
  {
    countryCode: "MO",
    countryName: "Macao",
    currencyCode: "MOP",
    population: "449198",
    capital: "Macao",
    continentName: "Asia",
  },
  {
    countryCode: "MP",
    countryName: "Northern Mariana Islands",
    currencyCode: "USD",
    population: "53883",
    capital: "Saipan",
    continentName: "Oceania",
  },
  {
    countryCode: "MQ",
    countryName: "Martinique",
    currencyCode: "EUR",
    population: "432900",
    capital: "Fort-de-France",
    continentName: "North America",
  },
  {
    countryCode: "MR",
    countryName: "Mauritania",
    currencyCode: "MRO",
    population: "3205060",
    capital: "Nouakchott",
    continentName: "Africa",
  },
  {
    countryCode: "MS",
    countryName: "Montserrat",
    currencyCode: "XCD",
    population: "9341",
    capital: "Plymouth",
    continentName: "North America",
  },
  {
    countryCode: "MT",
    countryName: "Malta",
    currencyCode: "EUR",
    population: "403000",
    capital: "Valletta",
    continentName: "Europe",
  },
  {
    countryCode: "MU",
    countryName: "Mauritius",
    currencyCode: "MUR",
    population: "1294104",
    capital: "Port Louis",
    continentName: "Africa",
  },
  {
    countryCode: "MV",
    countryName: "Maldives",
    currencyCode: "MVR",
    population: "395650",
    capital: "Malé",
    continentName: "Asia",
  },
  {
    countryCode: "MW",
    countryName: "Malawi",
    currencyCode: "MWK",
    population: "15447500",
    capital: "Lilongwe",
    continentName: "Africa",
  },
  {
    countryCode: "MX",
    countryName: "Mexico",
    currencyCode: "MXN",
    population: "112468855",
    capital: "Mexico City",
    continentName: "North America",
  },
  {
    countryCode: "MY",
    countryName: "Malaysia",
    currencyCode: "MYR",
    population: "28274729",
    capital: "Kuala Lumpur",
    continentName: "Asia",
  },
  {
    countryCode: "MZ",
    countryName: "Mozambique",
    currencyCode: "MZN",
    population: "22061451",
    capital: "Maputo",
    continentName: "Africa",
  },
  {
    countryCode: "NA",
    countryName: "Namibia",
    currencyCode: "NAD",
    population: "2128471",
    capital: "Windhoek",
    continentName: "Africa",
  },
  {
    countryCode: "NC",
    countryName: "New Caledonia",
    currencyCode: "XPF",
    population: "216494",
    capital: "Noumea",
    continentName: "Oceania",
  },
  {
    countryCode: "NE",
    countryName: "Niger",
    currencyCode: "XOF",
    population: "15878271",
    capital: "Niamey",
    continentName: "Africa",
  },
  {
    countryCode: "NF",
    countryName: "Norfolk Island",
    currencyCode: "AUD",
    population: "1828",
    capital: "Kingston",
    continentName: "Oceania",
  },
  {
    countryCode: "NG",
    countryName: "Nigeria",
    currencyCode: "NGN",
    population: "154000000",
    capital: "Abuja",
    continentName: "Africa",
  },
  {
    countryCode: "NI",
    countryName: "Nicaragua",
    currencyCode: "NIO",
    population: "5995928",
    capital: "Managua",
    continentName: "North America",
  },
  {
    countryCode: "NL",
    countryName: "Netherlands",
    currencyCode: "EUR",
    population: "16645000",
    capital: "Amsterdam",
    continentName: "Europe",
  },
  {
    countryCode: "NO",
    countryName: "Norway",
    currencyCode: "NOK",
    population: "5009150",
    capital: "Oslo",
    continentName: "Europe",
  },
  {
    countryCode: "NP",
    countryName: "Nepal",
    currencyCode: "NPR",
    population: "28951852",
    capital: "Kathmandu",
    continentName: "Asia",
  },
  {
    countryCode: "NR",
    countryName: "Nauru",
    currencyCode: "AUD",
    population: "10065",
    capital: "Yaren",
    continentName: "Oceania",
  },
  {
    countryCode: "NU",
    countryName: "Niue",
    currencyCode: "NZD",
    population: "2166",
    capital: "Alofi",
    continentName: "Oceania",
  },
  {
    countryCode: "NZ",
    countryName: "New Zealand",
    currencyCode: "NZD",
    population: "4252277",
    capital: "Wellington",
    continentName: "Oceania",
  },
  {
    countryCode: "OM",
    countryName: "Oman",
    currencyCode: "OMR",
    population: "2967717",
    capital: "Muscat",
    continentName: "Asia",
  },
  {
    countryCode: "PA",
    countryName: "Panama",
    currencyCode: "PAB",
    population: "3410676",
    capital: "Panama City",
    continentName: "North America",
  },
  {
    countryCode: "PE",
    countryName: "Peru",
    currencyCode: "PEN",
    population: "29907003",
    capital: "Lima",
    continentName: "South America",
  },
  {
    countryCode: "PF",
    countryName: "French Polynesia",
    currencyCode: "XPF",
    population: "270485",
    capital: "Papeete",
    continentName: "Oceania",
  },
  {
    countryCode: "PG",
    countryName: "Papua New Guinea",
    currencyCode: "PGK",
    population: "6064515",
    capital: "Port Moresby",
    continentName: "Oceania",
  },
  {
    countryCode: "PH",
    countryName: "Philippines",
    currencyCode: "PHP",
    population: "99900177",
    capital: "Manila",
    continentName: "Asia",
  },
  {
    countryCode: "PK",
    countryName: "Pakistan",
    currencyCode: "PKR",
    population: "184404791",
    capital: "Islamabad",
    continentName: "Asia",
  },
  {
    countryCode: "PL",
    countryName: "Poland",
    currencyCode: "PLN",
    population: "38500000",
    capital: "Warsaw",
    continentName: "Europe",
  },
  {
    countryCode: "PM",
    countryName: "Saint Pierre and Miquelon",
    currencyCode: "EUR",
    population: "7012",
    capital: "Saint-Pierre",
    continentName: "North America",
  },
  {
    countryCode: "PN",
    countryName: "Pitcairn Islands",
    currencyCode: "NZD",
    population: "46",
    capital: "Adamstown",
    continentName: "Oceania",
  },
  {
    countryCode: "PR",
    countryName: "Puerto Rico",
    currencyCode: "USD",
    population: "3916632",
    capital: "San Juan",
    continentName: "North America",
  },
  {
    countryCode: "PS",
    countryName: "Palestine",
    currencyCode: "ILS",
    population: "3800000",
    capital: "",
    continentName: "Asia",
  },
  {
    countryCode: "PT",
    countryName: "Portugal",
    currencyCode: "EUR",
    population: "10676000",
    capital: "Lisbon",
    continentName: "Europe",
  },
  {
    countryCode: "PW",
    countryName: "Palau",
    currencyCode: "USD",
    population: "19907",
    capital: "Melekeok",
    continentName: "Oceania",
  },
  {
    countryCode: "PY",
    countryName: "Paraguay",
    currencyCode: "PYG",
    population: "6375830",
    capital: "Asunción",
    continentName: "South America",
  },
  {
    countryCode: "QA",
    countryName: "Qatar",
    currencyCode: "QAR",
    population: "840926",
    capital: "Doha",
    continentName: "Asia",
  },
  {
    countryCode: "RE",
    countryName: "Réunion",
    currencyCode: "EUR",
    population: "776948",
    capital: "Saint-Denis",
    continentName: "Africa",
  },
  {
    countryCode: "RO",
    countryName: "Romania",
    currencyCode: "RON",
    population: "21959278",
    capital: "Bucharest",
    continentName: "Europe",
  },
  {
    countryCode: "RS",
    countryName: "Serbia",
    currencyCode: "RSD",
    population: "7344847",
    capital: "Belgrade",
    continentName: "Europe",
  },
  {
    countryCode: "RU",
    countryName: "Russia",
    currencyCode: "RUB",
    population: "140702000",
    capital: "Moscow",
    continentName: "Europe",
  },
  {
    countryCode: "RW",
    countryName: "Rwanda",
    currencyCode: "RWF",
    population: "11055976",
    capital: "Kigali",
    continentName: "Africa",
  },
  {
    countryCode: "SA",
    countryName: "Saudi Arabia",
    currencyCode: "SAR",
    population: "25731776",
    capital: "Riyadh",
    continentName: "Asia",
  },
  {
    countryCode: "SB",
    countryName: "Solomon Islands",
    currencyCode: "SBD",
    population: "559198",
    capital: "Honiara",
    continentName: "Oceania",
  },
  {
    countryCode: "SC",
    countryName: "Seychelles",
    currencyCode: "SCR",
    population: "88340",
    capital: "Victoria",
    continentName: "Africa",
  },
  {
    countryCode: "SD",
    countryName: "Sudan",
    currencyCode: "SDG",
    population: "35000000",
    capital: "Khartoum",
    continentName: "Africa",
  },
  {
    countryCode: "SE",
    countryName: "Sweden",
    currencyCode: "SEK",
    population: "9828655",
    capital: "Stockholm",
    continentName: "Europe",
  },
  {
    countryCode: "SG",
    countryName: "Singapore",
    currencyCode: "SGD",
    population: "4701069",
    capital: "Singapore",
    continentName: "Asia",
  },
  {
    countryCode: "SH",
    countryName: "Saint Helena",
    currencyCode: "SHP",
    population: "7460",
    capital: "Jamestown",
    continentName: "Africa",
  },
  {
    countryCode: "SI",
    countryName: "Slovenia",
    currencyCode: "EUR",
    population: "2007000",
    capital: "Ljubljana",
    continentName: "Europe",
  },
  {
    countryCode: "SJ",
    countryName: "Svalbard and Jan Mayen",
    currencyCode: "NOK",
    population: "2550",
    capital: "Longyearbyen",
    continentName: "Europe",
  },
  {
    countryCode: "SK",
    countryName: "Slovakia",
    currencyCode: "EUR",
    population: "5455000",
    capital: "Bratislava",
    continentName: "Europe",
  },
  {
    countryCode: "SL",
    countryName: "Sierra Leone",
    currencyCode: "SLL",
    population: "5245695",
    capital: "Freetown",
    continentName: "Africa",
  },
  {
    countryCode: "SM",
    countryName: "San Marino",
    currencyCode: "EUR",
    population: "31477",
    capital: "San Marino",
    continentName: "Europe",
  },
  {
    countryCode: "SN",
    countryName: "Senegal",
    currencyCode: "XOF",
    population: "12323252",
    capital: "Dakar",
    continentName: "Africa",
  },
  {
    countryCode: "SO",
    countryName: "Somalia",
    currencyCode: "SOS",
    population: "10112453",
    capital: "Mogadishu",
    continentName: "Africa",
  },
  {
    countryCode: "SR",
    countryName: "Suriname",
    currencyCode: "SRD",
    population: "492829",
    capital: "Paramaribo",
    continentName: "South America",
  },
  {
    countryCode: "SS",
    countryName: "South Sudan",
    currencyCode: "SSP",
    population: "8260490",
    capital: "Juba",
    continentName: "Africa",
  },
  {
    countryCode: "ST",
    countryName: "São Tomé and Príncipe",
    currencyCode: "STD",
    population: "175808",
    capital: "São Tomé",
    continentName: "Africa",
  },
  {
    countryCode: "SV",
    countryName: "El Salvador",
    currencyCode: "USD",
    population: "6052064",
    capital: "San Salvador",
    continentName: "North America",
  },
  {
    countryCode: "SX",
    countryName: "Sint Maarten",
    currencyCode: "ANG",
    population: "37429",
    capital: "Philipsburg",
    continentName: "North America",
  },
  {
    countryCode: "SY",
    countryName: "Syria",
    currencyCode: "SYP",
    population: "22198110",
    capital: "Damascus",
    continentName: "Asia",
  },
  {
    countryCode: "SZ",
    countryName: "Swaziland",
    currencyCode: "SZL",
    population: "1354051",
    capital: "Mbabane",
    continentName: "Africa",
  },
  {
    countryCode: "TC",
    countryName: "Turks and Caicos Islands",
    currencyCode: "USD",
    population: "20556",
    capital: "Cockburn Town",
    continentName: "North America",
  },
  {
    countryCode: "TD",
    countryName: "Chad",
    currencyCode: "XAF",
    population: "10543464",
    capital: "N'Djamena",
    continentName: "Africa",
  },
  {
    countryCode: "TF",
    countryName: "French Southern Territories",
    currencyCode: "EUR",
    population: "140",
    capital: "Port-aux-Français",
    continentName: "Antarctica",
  },
  {
    countryCode: "TG",
    countryName: "Togo",
    currencyCode: "XOF",
    population: "6587239",
    capital: "Lomé",
    continentName: "Africa",
  },
  {
    countryCode: "TH",
    countryName: "Thailand",
    currencyCode: "THB",
    population: "67089500",
    capital: "Bangkok",
    continentName: "Asia",
  },
  {
    countryCode: "TJ",
    countryName: "Tajikistan",
    currencyCode: "TJS",
    population: "7487489",
    capital: "Dushanbe",
    continentName: "Asia",
  },
  {
    countryCode: "TK",
    countryName: "Tokelau",
    currencyCode: "NZD",
    population: "1466",
    capital: "",
    continentName: "Oceania",
  },
  {
    countryCode: "TL",
    countryName: "East Timor",
    currencyCode: "USD",
    population: "1154625",
    capital: "Dili",
    continentName: "Oceania",
  },
  {
    countryCode: "TM",
    countryName: "Turkmenistan",
    currencyCode: "TMT",
    population: "4940916",
    capital: "Ashgabat",
    continentName: "Asia",
  },
  {
    countryCode: "TN",
    countryName: "Tunisia",
    currencyCode: "TND",
    population: "10589025",
    capital: "Tunis",
    continentName: "Africa",
  },
  {
    countryCode: "TO",
    countryName: "Tonga",
    currencyCode: "TOP",
    population: "122580",
    capital: "Nuku'alofa",
    continentName: "Oceania",
  },
  {
    countryCode: "TR",
    countryName: "Turkey",
    currencyCode: "TRY",
    population: "77804122",
    capital: "Ankara",
    continentName: "Asia",
  },
  {
    countryCode: "TT",
    countryName: "Trinidad and Tobago",
    currencyCode: "TTD",
    population: "1228691",
    capital: "Port of Spain",
    continentName: "North America",
  },
  {
    countryCode: "TV",
    countryName: "Tuvalu",
    currencyCode: "AUD",
    population: "10472",
    capital: "Funafuti",
    continentName: "Oceania",
  },
  {
    countryCode: "TW",
    countryName: "Taiwan",
    currencyCode: "TWD",
    population: "22894384",
    capital: "Taipei",
    continentName: "Asia",
  },
  {
    countryCode: "TZ",
    countryName: "Tanzania",
    currencyCode: "TZS",
    population: "41892895",
    capital: "Dodoma",
    continentName: "Africa",
  },
  {
    countryCode: "UA",
    countryName: "Ukraine",
    currencyCode: "UAH",
    population: "45415596",
    capital: "Kiev",
    continentName: "Europe",
  },
  {
    countryCode: "UG",
    countryName: "Uganda",
    currencyCode: "UGX",
    population: "33398682",
    capital: "Kampala",
    continentName: "Africa",
  },
  {
    countryCode: "UM",
    countryName: "U.S. Minor Outlying Islands",
    currencyCode: "USD",
    population: "0",
    capital: "",
    continentName: "Oceania",
  },
  {
    countryCode: "US",
    countryName: "United States",
    currencyCode: "USD",
    population: "310232863",
    capital: "Washington",
    continentName: "North America",
  },
  {
    countryCode: "UY",
    countryName: "Uruguay",
    currencyCode: "UYU",
    population: "3477000",
    capital: "Montevideo",
    continentName: "South America",
  },
  {
    countryCode: "UZ",
    countryName: "Uzbekistan",
    currencyCode: "UZS",
    population: "27865738",
    capital: "Tashkent",
    continentName: "Asia",
  },
  {
    countryCode: "VA",
    countryName: "Vatican City",
    currencyCode: "EUR",
    population: "921",
    capital: "Vatican City",
    continentName: "Europe",
  },
  {
    countryCode: "VC",
    countryName: "Saint Vincent and the Grenadines",
    currencyCode: "XCD",
    population: "104217",
    capital: "Kingstown",
    continentName: "North America",
  },
  {
    countryCode: "VE",
    countryName: "Venezuela",
    currencyCode: "VEF",
    population: "27223228",
    capital: "Caracas",
    continentName: "South America",
  },
  {
    countryCode: "VG",
    countryName: "British Virgin Islands",
    currencyCode: "USD",
    population: "21730",
    capital: "Road Town",
    continentName: "North America",
  },
  {
    countryCode: "VI",
    countryName: "U.S. Virgin Islands",
    currencyCode: "USD",
    population: "108708",
    capital: "Charlotte Amalie",
    continentName: "North America",
  },
  {
    countryCode: "VN",
    countryName: "Vietnam",
    currencyCode: "VND",
    population: "89571130",
    capital: "Hanoi",
    continentName: "Asia",
  },
  {
    countryCode: "VU",
    countryName: "Vanuatu",
    currencyCode: "VUV",
    population: "221552",
    capital: "Port Vila",
    continentName: "Oceania",
  },
  {
    countryCode: "WF",
    countryName: "Wallis and Futuna",
    currencyCode: "XPF",
    population: "16025",
    capital: "Mata-Utu",
    continentName: "Oceania",
  },
  {
    countryCode: "WS",
    countryName: "Samoa",
    currencyCode: "WST",
    population: "192001",
    capital: "Apia",
    continentName: "Oceania",
  },
  {
    countryCode: "XK",
    countryName: "Kosovo",
    currencyCode: "EUR",
    population: "1800000",
    capital: "Pristina",
    continentName: "Europe",
  },
  {
    countryCode: "YE",
    countryName: "Yemen",
    currencyCode: "YER",
    population: "23495361",
    capital: "Sanaa",
    continentName: "Asia",
  },
  {
    countryCode: "YT",
    countryName: "Mayotte",
    currencyCode: "EUR",
    population: "159042",
    capital: "Mamoudzou",
    continentName: "Africa",
  },
  {
    countryCode: "ZA",
    countryName: "South Africa",
    currencyCode: "ZAR",
    population: "49000000",
    capital: "Pretoria",
    continentName: "Africa",
  },
  {
    countryCode: "ZM",
    countryName: "Zambia",
    currencyCode: "ZMW",
    population: "13460305",
    capital: "Lusaka",
    continentName: "Africa",
  },
  {
    countryCode: "ZW",
    countryName: "Zimbabwe",
    currencyCode: "ZWL",
    population: "13061000",
    capital: "Harare",
    continentName: "Africa",
  },
];
let date = new Date();

date.setDate(date.getDate() - 1);
export const user = {
  username: "",
  dob: date,
  sex: "female",
  address: "",
  postal_address: "",
  email: "",
  phone: "",
  kin_phone: "",
  user_id: "",
  id_type: "",
  id_num: "",
  photoUrl: null,
  education: "",
  amount: 0,
  agro_training: "",
  qualifications: "",
  occupation: "",
  agro_type: "",
  agro_interest: "",
  land_type: "",
  farm_loc: "",
  land_access: "",
  farming_activities: "",
  involvement: "",
  belonging: "",
  cooperative_name: "",
  youth_committee: "",
  fulltime_parttime: "",
  long_term_income: "",
  date_created: date,
  sub: date,
  isComplete: false,
  challanges: "",
  support: "",
};

export const packages = [
  {
    name: "youth",
    description: "Preminium membership for an individual youth",
    ZMW: 250,
  },
  {
    name: "above 35",
    description: "Preminium membership for an individual above 35 years old",
    ZMW: 500,
  },
  {
    name: "group",
    description: "Preminium membership for a group, cooperative, company",
    ZMW: 400,
  },
  {
    name: "free",
    description: "Free membership for limitted access",
    ZMW: 0,
  },
];
export const sexOpt = ["Select sex", "Female", "Male"];
export const idOpt = ["Select id", "NRC", "Passport", `Driver's License`];
export const educationOpt = [
  "Select education level",
  "Primary",
  "Secondary",
  "Tertiary",

  "Other",
];
export const levelOpt = [
  "Select education qualification",
  "Certificate",
  "Diploma",
  "Degree",

  "Masters Degree",
  "PhD",
  "None",
];
export const occuptionOpt = [
  "Select occuption",
  "Employed",
  "Self-employed",
  "Student",

  "UnEmployed",
];
export const agroOpt = [
  "Select farming interests",
  "Emerging",
  "Small scale",
  "Semi-Commercial",

  "Commercial",
  "Only interested",
];
export const typeOpt = [
  "Select area of interest",
  "crops",
  "Horticulture",
  "Livestock",

  "Poultry",
  "Fish",
  "Services",
  "ICT",
];
export const selOpt = ["Select option", "Yes", "No"];
export const selMaybeOpt = [...selOpt, "Maybe"];
export const fullHustleOpt = ["Select option", "Full time", "Side hustle"];

export const user_form = {
  Personal_details: [
    {
      name: "username",
      title: "Your name*",

      type: "text",
      hint: "Your name*",
    },
    {
      name: "dob",
      title: "Enter date of birth*",

      type: "date",
      payload: [new Date()],
    },
    {
      name: "sex",
      title: "Sex*",

      type: "select",

      payload: sexOpt,
    },
    {
      name: "id_type",
      title: "ID type*",

      type: "select",

      payload: idOpt,
    },
    {
      name: "id_num",
      title: "ID number*",

      type: "text",

      hint: "Your id number*",
    },
  ],
  Contact_details: [
    {
      name: "email",
      title: "Email address (not shown)*",
      type: "email",
      hint: "email",
    },
    {
      name: "phone",
      title: "Phone number*",
      type: "text",
      hint: "Phone number*",
    },
    {
      name: "address",
      title: "Physical address*",
      type: "textarea",
      hint: "Physical address*",
    },
    {
      name: "postal_address",
      title: "Postal address",
      type: "textarea",
      hint: "Postal address",
    },
    {
      name: "kin_phone",
      title: "Next of kin phone number",
      type: "text",
      hint: "Next of kin phone number",
    },
  ],
  Background: [
    {
      title: "Education level*",
      name: "education",

      type: "select",

      payload: educationOpt,
    },

    {
      name: "qualifications",
      title: "Qualifications",
      type: "select",

      payload: levelOpt,
    },

    {
      title: "Have you done any agro training?*",
      name: "agro_training",

      type: "select",

      payload: selOpt,
    },
    {
      title: "Occupation*",
      name: "occupation",

      type: "select",

      payload: occuptionOpt,
    },
  ],
  Farming_interests: [
    {
      name: "agro_interest",
      title: "What are your farming interests?*",

      type: "select",

      payload: agroOpt,
    },
    {
      name: "agro_type",
      title: "What is your area of interest?*",

      type: "select",

      payload: typeOpt,
    },
    {
      name: "land_type",
      title: "Do you own land?*",

      type: "select",

      payload: [...selOpt, "Renting"],
    },
    {
      name: "farm_loc",
      title: "If Yes or Renting, what is the location of the farm?",
      type: "text",
      hint: "area,district,province",
    },
    {
      name: "land_access",
      title: "Do you have access to land in Zambia?*",

      type: "select",

      payload: selMaybeOpt,
    },
    {
      name: "farming_activities",
      title: "Are you currently involved in any farming activities?*",

      type: "select",

      payload: selMaybeOpt,
    },
    {
      name: "involvement",
      title: "If Yes, what is your level of involvement?",
      type: "text",
      hint: "level of involvement",
    },
    {
      name: "belonging",
      title: "Do you belong to any cooperative?*",

      type: "select",

      payload: selOpt,
    },
    {
      name: "cooperative_name",
      title: "If Yes, what is the name of the cooperative?",
      type: "text",
      hint: "cooperative_name",
    },
    {
      name: "youth_committee",
      title: "Are there any youth on the committee of your cooperative?",

      type: "select",

      payload: [...selOpt, "Not sure"],
    },
    {
      name: "fulltime_parttime",
      title: "Do you wish to purse farming full time or as a side hustle?",

      type: "select",

      payload: fullHustleOpt,
    },
    {
      name: "long_term_income",
      title:
        "Do you consider farming to be your main source of income in the long term?",

      type: "select",

      payload: selMaybeOpt,
    },
    {
      name: "challenges",
      title: "What are your challenges?*",
      type: "textarea",
      hint: "challenges",
    },
    {
      name: "support",
      title:
        "What kind of support would you need from any well wisher or donor?*",
      type: "textarea",
      hint: "support",
    },
  ],
};
export const actions = [
  {
    title: "Make Admin",
    info: "Add site adminstrators",
  },
];
export const pack = [
  "Access to information on agriculture",
  "Linkages to support structures/development partners",
  "Farm Visits",
];
export const benefits = {
  Premium_Membership: [
    ...pack,
    "Access to the YEFI Fund",
    "Discounts at supporting Agribusiness companies",
    "Access to cheaper land for agribusiness",
    "Student attachment / Scholarships",
  ],
  Ordinary_Membership: pack,
};
export const faqs = [
  {
    title: "1. Does YEFI give funding to Young Farmers?",
    info: "YEFI provides financial support to young farmers through its projects. Currently working on fundraising funds to start funding youths via application processes. However, YEFI facilitates the linkage of young farmers to financial support/institutions.",
  },
  {
    title: "2. How can I become a member?",
    info: `Can either a premium member (Paid up) or Ordinary member:`,
    link: [
      {
        name: "You can register via our platform",
        goto: "/subscribe",
      },
      {
        name: "contact Secretariat to get assist on how to become a member",
        goto: "/contactus",
      },
    ],
  },
  {
    title: "3. What are the benefits of being a YEFI member?",
    info: `Premium Member

    - Access to information on agriculture
    - Linkages to support structures/development partners
    - Farm Visits
    - Access to the YEFI Fund
    - Discounts at supporting Agribusiness companies
    - Access to cheaper land for agribusiness 
    - Student attachment / Scholarships

    Ordinary Member 

    - Access to information on agriculture
    - Linkages to support structures/development partners
    - Farm Visits
    
    `,
  },
  {
    title: "4. How does YEFI engage young farmers?",
    info: "YEFI encourages young farmers to work in organized groups, YEFI also facilitate the registration of youth groups in agriculture.",
  },
  {
    title: "4. What services does YEFI offer?",
    info: "Business Development Services (Market linkages and development, linkage to finances, Business plan development e.t.c. ), Consultancy, Linkage to experts, access to agriculture information.",
  },
];
export const projects = [
  {
    title: "ZYCALA Project",
    info: `Initiated in 2017 and ongoing, ZYCALA focuses on building capacity among Zambian youth
    to become Agents of Change in their communities. Operating in the Central province of
    Zambia, the project spans Mkushi, Serenje, and Chitambo. A £400, 000+ project with
    tangible impact.`,
    achievements: [
      `Engaged over 3000 beneficiaries as active change agents across 12 demonstration
      sites.`,
      `Trained over 1000 youths in conservation, agriculture, and livelihood skills.`,
      `Reached 78,323 youths through campaigns and impacted 2200 households.`,
      `Achieved 100% livelihood improvement for beneficiaries, influencing incomes from
      200 ZMK to over 5000 ZMK.`,
      `Reached over 150,000 provincial and national youths through social media platforms.`,
      `Successfully linked project beneficiaries to a honey market in Europe (LUSH).`,
    ],
  },
  {
    title: "Young Agriculture Conservation Agents (YACA)",
    info: `Initiated in 2019, YACA aims to
    empower young people in Muchinga and Eastern provinces to become agents of change by
    promoting climate-smart agriculture aligned with the SDGs. A $100, 000 project supported
    by more than one donor.`,
    achievements: [
      `Trained over 1000 young people in sustainable agriculture techniques and business.`,
      `Planted 3000 trees in 10 schools.`,
      `Established two enterprises and linked them to financial resources for agro-businesses.`,
    ],
  },
  {
    title: `Championing of Climate-Smart Agricultural Practices to Youth Based
    Agribusinesses for Better Environmental and Natural Resources
    Management in Zambia.`,
    info: `The project aims to build capacity in
    young people to know good natural
    resource management and promote
    good agricultural practices. The
    project was undertaken in three
    districts Kasama (Northern
      province), Mbala (Northern provice)
      and Chipili (Luapula province)
      districts.
      `,
    achievements: [`241 young people trained.`],
  },
  {
    title: "Aquaculture Business Opportunity Seminars (ABOS)",
    info: `Conducted in partnership with the
    Aquaculture Development Association of Zambia (ADAZ) under the Yapasa project, ABOS
    began in 2017 to develop the aquaculture sector in Northern, Luapula, North-Western, and
    Lusaka provinces.`,
    achievements: [
      `Trained over 100 youths in aquaculture.`,
      `Expanded YEFI membership in areas where ABOS were conducted.`,
      `Conducted successful surveys contributing to project design and implementation.`,
    ],
  },
  {
    title: "My Farm My Swag Campaign ",
    info: `The program was launched in 2016 and is an ongoing program. This is an advocacy program aimed at
    changing the mindset of young people to take agriculture as a business (agribusiness). This program is
    running in all 10 provinces of the country through our network of volunteers. A $200, 000 project, in over
    35 districts so far.`,
    achievements: [
      `Stimulated interest of over 100, 000 young people in Zambia to take up agriculture as a business.`,
      `Trained over 10, 000 youths in agribusiness.`,
      `Identified 10 young role models for young people for inspiration and motivation.`,
      `Partnered with over 10 relevant stakeholders to create an enabling environment for youths to have
      an entry point in the sector.`,
    ],
  },
  {
    title: "Strategic Partnership Agreement II (SPA II) Project",
    info: `“Strategic partnership agreement II” (SPA II) Project - 2023. The overall goal of the
    project is to achieve a Just, feminist, resilient and green societies through young people’s
    action.
    Geographical Coverage for the Project - 
    The project targets the following Provinces.
    Northern (Mbala/Mpulungu, Southern (Gwembe) Muchinga (Nakonde), Western (Sesheke
    and Nalolo) and Lusaka Province.
    YEFI has been operating in Sesheke, Nalolo and Gwembe districts with a focus to ensure
    Young people, especially women, and marginalized people living in fragile contexts enjoy
    improved, sustainable livelihoods through climate accountable governments and alternatives
    that build climate resilience. YEFI I is also fostering Young people, especially women, and
    marginalized people to become resilient to shocks and enjoy their right to protection in
    fragile situations, disasters and protracted crises.`,
    achievements: [
      `Over 100 youths trained in sustainable production.`,
      `Facilitated the establishment and registration of a youth-led Cooperative.`,
      `Facilitated the establishment of 3 youth-led SILC groups.`,
      `Linked youths to local financing and market structures.`,
    ],
  },
  {
    title: "Farm Visits – Field Days    ",
    info: `The program was initiated in 2015 and is running in all parts of the
    country. The program is in partners with Small Scale farms: Farming as
    a business platform and Tuzini Farms. This program aims to expose the
    young people to already existing and operating farms especially the
    ones that practices different and innovative techniques with as value
    addition consideration.`,
    achievements: [
      `Linked over 100 agripreneurs.    `,
      `Stimulated interest to undertake agribusiness in over 150 young
  people.`,
      `Trained over 200 young people through practical exposure sessions.`,
    ],
  },
];
