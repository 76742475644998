import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router";
import { bindActionCreators } from "redux";
import { getDocFirestore } from "../actions";
import SubNav from "../components/SubNav";
import profile from "../assets/profile.webp";
import styled from "styled-components";
import { user_form } from "../constants/AppData";
import { GREEN, LIGHTBLUE } from "../constants/Colors";
import { startCase, keys } from "lodash";
import CustomBtn from "../components/CustomBtn";

const Div = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 1px 0px;

  span {
    text-align: start;
    margin: 0px 10px 0px 0px;
    font-weight: bold;
  }
`;

const Profile = ({ getDocFirestore, uid }) => {
  const history = useHistory();
  const [data, setdata] = useState();
  const params = useParams();

  useEffect(() => {
    try {
      const { post } = history.location.state;
      console.log("get job  ");

      setdata(post);
    } catch (error) {
      console.log("get job err ");
      getDocFirestore(`users`, params.id, setdata, false);
    }
  }, []);
  return (
    <Container>
      <SubNav />
      <br />
      {data && (
        <Row style={{ marginBottom: 20 }}>
          <Col md="auto">
            <br />

            <Image src={data.photoUrl || profile} alt={"profile"} width={171} />
            {uid === params.id && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                <CustomBtn
                  label={"Edit Profile"}
                  type="button"
                  btnClicked={() =>
                    history.push(
                      `/edit_profile/return?from=${history.location.pathname.replace(
                        "/",
                        "%2F"
                      )}`
                    )
                  }
                  color1={GREEN}
                  color2={LIGHTBLUE}
                  margin="3px"
                />
              </div>
            )}
          </Col>
          <Col>
            {keys(user_form).map((title) => (
              <div key={title}>
                <br />

                <h3 style={{ color: GREEN }}>{startCase(title)}</h3>
                {user_form[title].map((entry) => (
                  <Div key={entry.name}>
                    <span>{startCase(entry.name)}</span>
                    {entry.type === "date"
                      ? new Date(data[entry.name]).toString().substring(4, 15)
                      : data[entry.name]}
                  </Div>
                ))}
              </div>
            ))}
          </Col>
        </Row>
      )}
    </Container>
  );
};
const mapStateToProps = ({ yng_authReducer }) => {
  return {
    uid: yng_authReducer.current?.uid,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getDocFirestore }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
