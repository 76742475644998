import { useRef } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import ImageScroller from "../components/ImageScroller";
import ngg from "../assets/IMG_20231117_095909_919.jpg";
import ngg1 from "../assets/IMG_3560.webp";
import ngg2 from "../assets/IMG_20200910_125117_4.webp";
import ngg3 from "../assets/IMG_3598.webp";
import ngg4 from "../assets/nawa2.webp";
import ngg5 from "../assets/IMG_1481.webp";

import Wrapper from "../wrapper";
import styled from "styled-components";
import { GREEN, LIGHTBLUE } from "../constants/Colors";
import { FaCheckSquare, FaKey } from "react-icons/fa";
import { projects } from "../constants/AppData";
import SplashComp from "../components/SplashComp";

const Span = styled.span`
  font-weight: bold;
`;
const Ul = styled.ul`
  list-style: none;
  li {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;

    h5,
    h6 {
      text-align: start;
      margin: 10px;
    }
  }
`;
const Impact = () => {
  const homeImg = [ngg, ngg1, ngg2, ngg3, ngg4, ngg5];
  const ref = useRef();
  return (
    <Wrapper>
      <ImageScroller bgs={homeImg} objectFit="cover" />

      <Row style={{ color: "black", padding: "10px 0px" }}>
        <Col>
          <SplashComp className="Icon-links" width="29em" />
        </Col>
        <Col>
          <h1 className="green-header">Our Impact </h1>
          <p>
            For more than 3 years, Young Emerging Farmers Initiative has
            identified the challenges or rather the barrier to entry in the
            agriculture sector. YEFI has strong financial systems to ensure
            accountability and transparent.
            <br />
            <br />
            YEFI has facilitated the linkage of over{" "}
            <Span>600 agripreneurs</Span> to support structures and expertise.
            We have been a platform of information dissemination on agriculture
            on its social media platforms (website and Facebook) as well as
            through its coordinators in the local areas of operation. YEFI has
            also established a Multi-Purpose Cooperative (social enterprise) as
            one of its sustainability strategy. In 2016 YEFI launched the{" "}
            <Span>‘My Farm My Swag Campaign’</Span> which has been an effective
            way to reach out and capture the attention of the youths especially
            in urban areas. We have a target of over{" "}
            <Span>500, 000 youths</Span> to reached and impacted in the next 10
            years.
            <br />
            <br />
            YEFI is a{" "}
            <span>
              <a href="https://www.equatorinitiative.org/2023/07/06/young-emerging-farmers-initiative-yefi/">
                2023 UNDP Equator Prize winner
              </a>
            </span>{" "}
            in recognition of outstanding success in promoting local climate
            change and sustainable development solutions for people, nature and
            resilient communities in Zambia.
          </p>
        </Col>
      </Row>
      <Row xs={1} sm={2}>
        <Col
          style={{
            padding: "0%",
          }}>
          <div
            style={{
              background: GREEN,
              color: "white",
              padding: "10%",

              flex: 1,
              height: "100%",
            }}>
            <h4>KEY AREAS OF FOCUS</h4>
            <Ul>
              <li>
                <FaKey size="2.4em" color={LIGHTBLUE} />
                <h5>Agribusiness</h5>
              </li>
              <li>
                <FaKey size="2.4em" color={LIGHTBLUE} />
                <h5>Climate Change Adaptation and Mitigation</h5>
              </li>
              <li>
                <FaKey size="2.4em" color={LIGHTBLUE} />
                <h5>Nutrition</h5>
              </li>
            </Ul>
          </div>
        </Col>
        <Col
          style={{
            padding: "0%",
          }}>
          <div
            style={{
              background: LIGHTBLUE,
              color: "white",
              padding: "10%",
            }}>
            <h4>OTHER AREAS</h4>
            <Ul>
              <li>
                <FaKey size="2.4em" color={GREEN} />
                <h5>Agricultural Mechanisation</h5>
              </li>
              <li>
                <FaKey size="2.4em" color={GREEN} />
                <h5>Health Living</h5>
              </li>
              <li>
                <FaKey size="2.4em" color={GREEN} />
                <h5>Coordination, Partnership and Networking </h5>
              </li>
              <li>
                <FaKey size="2.4em" color={GREEN} />
                <h5>Advocacy </h5>
              </li>
            </Ul>
          </div>
        </Col>
      </Row>
      <Row
        xs={1}
        sm={2}
        style={{
          borderRadius: "0px 0px 20px 20px",
          backgroundColor: "rgba(251, 251, 251, 0.7)",
        }}>
        <h1
          className="bgg"
          style={{
            width: "100%",
            padding: 10,
            textAlign: "center",
          }}>
          Programs
        </h1>
        <div
          style={{
            padding: "20px",
            width: "100%",
          }}>
          <Accordion
            style={{
              width: "100%",
            }}
            ref={ref}
            flush>
            {projects.map((file, idx) => (
              <Accordion.Item key={idx} eventKey={`${idx}`}>
                <Accordion.Header>
                  <h4>{file.title}</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <h5>{file.info}</h5>
                  <br />
                  <h5 style={{ color: LIGHTBLUE }}>Achievements</h5>

                  <Ul>
                    {file.achievements.map((entry, idx) => (
                      <li key={`${idx}`}>
                        <FaCheckSquare size="2.4em" color={GREEN} />
                        <h6>{entry}</h6>
                      </li>
                    ))}
                  </Ul>
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Row>
    </Wrapper>
  );
};

export default Impact;
