import React, { useEffect, useState } from "react";
import { Carousel, Image, Row } from "react-bootstrap";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const ImageScroller = ({ bgs, objectFit }) => {
  console.log("path docs callBgs bgs ", bgs);

  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    console.log("get index ImageScroller ", selectedIndex);

    setIndex(selectedIndex);
  };
  const [match, setMatch] = useState(
    window.matchMedia("(min-width: 575px)").matches
  );
  const [match1, setMatch1] = useState(
    window.matchMedia("(min-width: 875px)").matches
  );
  const handler = (e) => setMatch(e.matches);
  const handler1 = (e) => setMatch1(e.matches);
  useEffect(() => {
    window.matchMedia("(min-width: 575px)").addListener(handler);
    window.matchMedia("(min-width: 875px)").addListener(handler1);
  }, [bgs, objectFit]);
  return (
    <Row
      style={{
        height: !match && !match1 ? "17rem" : !match1 ? "20rem" : "30rem",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
      }}>
      <Carousel
        style={{
          width: "100%",

          height: !match && !match1 ? "17rem" : !match1 ? "20rem" : "30rem",
        }}
        activeIndex={index}
        onSelect={handleSelect}
        nextIcon={<FaAngleRight color="rgb(229, 122, 32)" size="2.0em" />}
        prevIcon={<FaAngleLeft color="rgb(229, 122, 32)" size="2.0em" />}>
        {bgs.map((file, idx) => (
          <Carousel.Item key={idx}>
            <div
              style={{
                width: "100%",
                height:
                  !match && !match1 ? "17rem" : !match1 ? "20rem" : "30rem",
              }}>
              <Image
                src={file}
                alt={"file"}
                style={{
                  width: "100%",
                  height: "100%",

                  objectFit,
                }}
              />
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </Row>
  );
};

export default ImageScroller;
