import { Row, Col, Accordion } from "react-bootstrap";
import ImageScroller from "../components/ImageScroller";
import ngg from "../assets/IMG_20231117_095909_919.jpg";
import ngg1 from "../assets/IMG_1108.webp";
import ngg2 from "../assets/IMG_368.webp";
//import ngg3 from "../assets/IMG_20200725_100546_3.jpg";

import Wrapper from "../wrapper";
import styled from "styled-components";
import { GREEN, LIGHTBLUE } from "../constants/Colors";
import { FaCheckSquare } from "react-icons/fa";
import { faqs } from "../constants/AppData";
import { useHistory } from "react-router";
import SplashComp from "../components/SplashComp";

const Ul = styled.ul`
  list-style: none;
  padding: 0px 27px;
  li {
    display: flex;
    flex-direction: row;
    margin: 10px 0px;

    h5 {
      text-align: start;
      margin: 10px;
    }
  }
`;
function Overview() {
  const homeImg = [ngg, ngg1, ngg2];
  const history = useHistory();
  return (
    <Wrapper>
      <ImageScroller bgs={homeImg} objectFit="cover" />
      <Row style={{ color: "black", padding: "10px 0px" }}>
        <Col>
          <SplashComp className="Icon-links" width="20em" />
        </Col>
        <Col style={{ color: "black", padding: "10px" }}>
          <h1 className="green-header">ABOUT US </h1>
          <p>
            Young Emerging Farmers Initiative (YEFI) is a Non-Governmental
            Organisation established in 2014 and registered under the NGO Act of
            the Laws of Zambia in 2015. YEFI is youth oriented, youth focused
            that works to promote, mobilize, facilitate, encourage, engage and
            empower youths both in rural and urban areas in agricultural
            activities that will contribute efficiently and effectively towards
            the creation of wealth and employment to the young people in Zambia,
            the regional and Africa as a whole.
            <br />
            <br />
            YEFI is a{" "}
            <span>
              <a href="https://www.equatorinitiative.org/2023/07/06/young-emerging-farmers-initiative-yefi/">
                2023 UNDP Equator Prize winner
              </a>
            </span>{" "}
            in recognition of outstanding success in promoting local climate
            change and sustainable development solutions for people, nature and
            resilient communities in Zambia.
          </p>
        </Col>
      </Row>
      <Row xs={1} sm={2}>
        <Col
          style={{
            padding: "0%",
          }}>
          <div
            style={{
              background: GREEN,
              color: "white",
              padding: "10%",

              flex: 1,
              height: "100%",
            }}>
            <h4>Our Vission</h4>
            <h6>An Empowered youth in agriculture</h6>
          </div>
        </Col>
        <Col
          style={{
            padding: "0%",
          }}>
          <div
            style={{
              background: LIGHTBLUE,
              color: "white",
              padding: "10%",
            }}>
            <h4>Our Mission</h4>
            <h6>
              To represent, mobilise and empower youths through training, and
              raising seed capital in agriculture activities, and awareness for
              climate change and healthy living.
            </h6>
          </div>
        </Col>
      </Row>
      <Row
        xs={1}
        sm={2}
        style={{
          borderRadius: "0px 0px 20px 20px",
          backgroundColor: "rgba(251, 251, 251, 0.7)",
        }}>
        <h1
          className="bgg"
          style={{
            width: "100%",
            padding: 10,
            textAlign: "center",
          }}>
          Our Core Values
        </h1>
        <Col>
          <Ul>
            <li>
              <FaCheckSquare size="2.4em" color={GREEN} />
              <h5>Transparency, honesty and integrity</h5>
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={GREEN} />
              <h5>Passion</h5>
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={GREEN} />
              <h5>Support Natural Resource Management</h5>
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={GREEN} />
              <h5>Support Gender Equity</h5>
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={GREEN} />
              <h5>Youths come first</h5>
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={GREEN} />
              <h5>Promote Climate-Smart Agriculture</h5>{" "}
            </li>
          </Ul>
        </Col>
        <Col>
          <Ul>
            <li>
              <FaCheckSquare size="2.4em" color={LIGHTBLUE} />
              <h5>Professionalism</h5>
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={LIGHTBLUE} />
              <h5>Striving for excellence at all times</h5>{" "}
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={LIGHTBLUE} />
              <h5>Dedication and commitment</h5>
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={LIGHTBLUE} />
              <h5>Justice and Equity </h5>
            </li>
            <li>
              <FaCheckSquare size="2.4em" color={LIGHTBLUE} />
              <h5>Respect for diversity and human rights</h5>{" "}
            </li>
          </Ul>
        </Col>
      </Row>
      <br />
      <Row
        xs={1}
        sm={2}
        style={{
          borderRadius: "0px 0px 20px 20px",
          backgroundColor: "rgba(251, 251, 251, 0.7)",
        }}>
        <h1
          className="bgg"
          style={{
            width: "100%",
            padding: 10,
            textAlign: "center",
          }}>
          FAQs
        </h1>
        <div
          style={{
            padding: "20px",
            width: "100%",
          }}>
          <Accordion
            style={{
              width: "100%",
            }}
            flush>
            {faqs.map((file, idx) => (
              <Accordion.Item key={idx} eventKey={`${idx}`}>
                <Accordion.Header>
                  <h4>{file.title}</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <h5>{file.info}</h5>
                  <br />

                  {file.link && (
                    <Ul>
                      {file.link.map((entry, idx) => (
                        <li key={`${idx}`}>
                          <span
                            style={{ color: "blue" }}
                            onClick={() => history.push(entry.goto)}>
                            {entry.name}
                          </span>
                        </li>
                      ))}
                    </Ul>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </div>
      </Row>
    </Wrapper>
  );
}

export default Overview;
