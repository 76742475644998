import React, { Component } from "react";
import styled from "styled-components";
import Burger from "./Burger";
import logo from "../../assets/logo.webp";
import { Link } from "react-router-dom";
import NavIcons from "./NavIcons";

const Nav = styled.nav`
  width: 100%;
  height: 85px;
  background-color: white;
  color: black;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  .logo {
    width: 200px;
  }
  .top-nav {
    display: flex;
    justify-content: space-between;
  }
  .bottom-nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .top-icons {
    display: none;
  }
  .bottom-icons {
    display: flex;
    justify-content: flex-end;
  }
  @media (max-width: 1199px) {
    flex-flow: column nowrap;
    height: 115px;
    .top-icons {
      display: flex;
      justify-content: flex-end;
    }
    .bottom-icons {
      display: none;
    }
  }
  @media (max-width: 1007px) {
    height: 55px;
    .top-icons {
      margin-right: 2.1rem;
    }
  }
  @media (max-width: 398px) {
    height: 55px;
    .logo {
      width: 100px;
    }
  }
`;

class Navbar extends Component {
  render() {
    return (
      <Nav>
        <div className="top-nav">
          <Link to="/">
            <img alt="logo" src={logo} className="logo" />
          </Link>
          <div className="top-icons">
            <NavIcons />
          </div>
        </div>
        <div className="bottom-nav">
          <div className="bottom-icons">
            <NavIcons />
          </div>
          <Burger />
        </div>
      </Nav>
    );
  }
}

export default Navbar;
